<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on" class="me-n3 mt-n2">
          <v-icon size="22">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link :to="{ name: 'crons' }">
          <v-list-item-title>Crons / Services timers</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'repeat-replace' }">
          <v-list-item-title>Repeat-replace with variables</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'copy-paste' }">
          <v-list-item-title>Multiple copy to clipboard</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'n-days' }">
          <v-list-item-title>Number of days from date</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mdiDotsVertical } from "@mdi/js";

export default {
  name: "DevMenu",
  data() {
    return {
      icons: {
        mdiDotsVertical,
      },
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
